.carousel-container {
    // max-width: 850px;
    // margin: auto;
}

.carousel.carousel-slider {
    margin: auto;
}

.carousel-slider {
    max-width: 830px;
    max-height: 600px;
    margin: auto;
}

li.thumb>div {
    margin: auto;
}

ul.thumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.carousel .control-dots {
    bottom: -105px;
    pointer-events: none;
    cursor: default;


    // display: none;
}

.carousel.carousel-slider {
    overflow: visible;

}

.carousel .carousel-status {
    font-size: 17px;
    right: 10px !important;
}

.carousel {
    @media (max-width: 750px) {
        padding-top: 88px;
    }

    .btn {
        position: absolute;
        width: calc(50% - 2px);
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 49%;

        &:not(:last-child) {
            margin: 0 2px 0 0;
        }
    }

    .ctrl-btn {
        &-prev {}

        &-next {
            left: initial;
            right: 0;

            &:not(:last-child) {
                margin: 0 0 0 2px;
            }
        }
    }

    .slider-wrapper {
        padding-bottom: 45px;
    }

    .dot {}
}

.img-logo {
    max-width: 250px;
    max-height: 250px;
}

.app-logo {
    position: absolute;
    top: 25px;
    left: 15px;
    z-index: 1;
}

.zada-id {
    max-width: 350px;
    max-height: 300px;
}

// .box {
//     border-radius: 5px;
//     padding: 5px;
//     margin: 5px;
//     border: 1px solid #000;
//     width: calc(50% - 10px);
//     align-items: center;
//     justify-content: center;
// }

.box {
    border-radius: 5px;
    padding: 7px;
    margin: 15px;
    border: 1px solid #000;
    width: calc(34% - 10px);
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 11px 1px #afacac;
    color: #007CC7;
    // background-color: #007CC7;
}

.control-box {
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    border: 1px solid #000;

    align-items: center;
    justify-content: center;
}

.input-box {
    margin-top: -124px !important;
    border-radius: 0.2rem;
    padding: 5px;
    font-size: 60px;
    // border: 1px solid #000;
    align-items: center;
    justify-content: center;

    padding-bottom: 1.4rem !important;
    padding-top: 1.4rem !important;

    //new changes

    width: 74% !important;
    margin: 7rem auto;
}

.input-box1 {
    margin-top: 80px;
    border-radius: 5px;
    padding: 5px;
    font-size: 60px;
    // border: 1px solid #000;
    align-items: center;
    justify-content: center;


    //new changes

    width: 74% !important;
    margin: 7rem auto;
}



.wrap-box {
    margin-top: 23px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    overflow: auto;
    margin-bottom: 10px;
}

.category-items {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px !important;
    margin-top: 23px;
}

.category-item {
    border-radius: 8px;
    border: 1px solid gray;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 48%;
    position: relative;
    cursor: pointer;
    height: 130px;
    
    a {
        padding: 0;
        margin-right: 10px;
    }

    padding: 10px 0px;
}

.category-icon {
    height: 32px;
    width: 32px;
    margin-right: 16px;
    margin-left: 16px;
}

.category-item-name {
    display: block;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
    font-size: 1.1em;
    font-weight: 500;
}

@media (max-width: 768px) {
    .category-item-name {
        font-size: 1.3em;
    }
}
.cust-prod-page {
    position: relative;
}

.cust-prod-page .nav .active {
    background-color: #12232E;

    a {
        color: #fff !important;
    }
}

.box {
    padding: 15px;
}

.phone-box {
    border: none;
}

.card.card-body {
    border-radius: 8px;
}

.custom-btn-margin {
    top: 498px;
}

.custom-div-scroll p {
    margin-bottom: 0rem !important;
    font-size: 0.6rem !important;
}

.custom-div-scroll2 {
    //  overflow: scroll !important;
}

.custom-div-scroll1 p:last-child {

    // .scrollspy-example {
    //     position: relative;
    //     height: 250px !important;
    //     margin-top: 1rem;
    //     overflow: auto;
    // }
    // height: 400px !important;
}

.qr-code-section-style .custom-btn-margin {
    width: 49% !important;
    margin-top: 3rem !important;
}

.qr-code-section-style .control-dots {
    bottom: -150px !important;
}

// .prod-section .btn-secondary{
//     width: 49% !important;
//     margin-top: 3em !important;
// }

// .prod-section .control-dots{
//     bottom: -150px !important;
// }

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    margin-top: -65px !important;

    margin-left: 65px !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.step {
    padding: 0;
    max-width: calc(12.5%);
    flex: 1 0 auto;
    text-align: center;
}

.step-label {
    color: white;
    max-width: clamp(100%, 10em, 100%);
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.step-text {
    color: white;
    max-width: calc(10em - 20px);
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

// .grecaptcha-badge {
//     display: none !important;
// }
// .grecaptcha-badge { visibility: hidden !important; }