.qrmodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }
  
  .qroverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  #spinner {
    text-align: center;
  }
  
  .qr-image{
    width: 300px;
    height: 300px;
  }
  
  .centered-content {
    align-content: center;
    text-align: center;
  }

  .dropdown {
    min-width: 100%;
    /* background-color: white; */
}

.menu-dropdown {
  min-width: 100%;
  background-color: white;
}
