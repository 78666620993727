@media (max-width: 480px) {
    .input-box {
        width: 100% !important;
    }

    .card-body {
        padding: 1rem 0.4rem !important;
    }

    .close-btn {
        margin-left: 284px !important;
        font-size: 0.9rem !important;
        line-height: 1 !important;

    }

    .policies-connectionless-link .p {
        font-size: 11px !important;
    }

    .box {
        width: calc(100% - 10px);
        margin: 10px;
    }

    .img-logo {
        max-width: 150px !important;
        max-height: 150px !important;
    }

    .list-group-item {
        margin-left: -31px;
    }

    .cust-verify-btn {
        margin-left: 288px !important;
        width: 66px !important;
    }

    body {
        font-size: 0.8rem !important;
    }

    .btn-group-sm>.btn,
    .btn-sm {

        font-size: 0.7rem !important;
    }

    .priv-state p {
        font-size: 0.8rem !important;
    }

    .accordion-button {
        font-size: 0.8rem !important;

    }

    .cust-prod-page {
        overflow: scroll !important;
        padding-bottom: 6rem !important;


    }

    .cust-prod-page .card-body {
        min-height: 600px !important;
        height: 600px !important;
    }

    .cust-prod-page .card-body .card-body {
        overflow: hidden !important;


    }

    .cust-prod-page .card.card-body {
        padding-bottom: 6rem !important;
        overflow: scroll !important;

    }

    .input-box {
        width: 86% !important;
        margin-left: 43px !important;
        margin-right: 0px !important;
        font-size: 5px !important;
    }

    .input-box1 {
        width: 100% !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
        margin: 20px 0px;
    }

    .phn-placeholder {
        font-size: 11px !important;

    }

    .modal-content {
        width: 90% !important;
        /* margin-top: 240px !important; */
        margin-left: 17px !important;
    }

    .rc-anchor-normal {
        width: 373px !important;
    }

    .rc-anchor-normal .rc-anchor-pt {
        margin: 2px 86px 0 0 !important;

    }

    .grecaptcha-badge {
        visibility: hidden !important;
    }


}

@media (max-width: 360px) {
    .box {
        width: calc(46% - 10px);
        margin: 10px;
    }

    .h2,
    h2 {
        font-size: 1.4rem !important;
    }


}

@media (max-width: 412px) {}


@media (max-width: 768px) {

    /* .img-logo {
        max-width: 134px;
        max-height: 100px;
    } */
    .img-logo {
        max-width: 168px !important;
        max-height: 168px !important;
    }

    .text-verified {
        width: 100px !important;
        font-size: 13px !important;
        margin-left: 470px !important;
        margin-top: -19px !important;


    }


}

@media (max-width: 991px) and (min-width: 768px) {

    .cust-verify-btn {
        margin-left: 490px !important;
    }

}

@media (max-width: 421px) and (min-width: 360px) {
    .cust-verify-btn {
        margin-left: 219px !important;
        width: 70px !important;

    }

    .policies-connectionless-link :first-child {
        font-size: 0.7rem !important;
    }

    .btn-group-sm>.btn,
    .btn-sm {
        font-size: 0.45rem !important;
    }

    body {
        font-size: 0.6rem !important;
    }

    .spinner-border-sm {
        width: 0.5rem !important;
        height: 0.5rem !important;
        padding: 1px;
    }

    .proofs-detail-data {
        font-size: 0.6rem !important;
    }

    .accordion-button {
        font-size: 0.6rem !important;
    }

    .text-verified {
        width: 65px !important;
        font-size: 13px !important;
        margin-left: 199px !important;
        margin-top: -19px !important;

    }

    .custom-div-scroll2 p {
        font-size: 0.5rem !important;

    }

    .accordion-body .scrollspy-example {
        bottom: 15px !important;
        max-height: 80px !important;
        overflow: auto !important;

    }

    .input-box {
        width: 86% !important;
        margin-left: 43px !important;
        margin-right: 0px !important;
    }

    .input-box1 {
        width: 100% !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
    }

    .modal-content {
        width: 90% !important;
        /* margin-top: 240px !important; */
        margin-left: 17px !important;
    }

    .rc-anchor-normal {
        width: 373px !important;
    }

    .grecaptcha-badge {
        display: none !important;
    }


    /* .recaptcha-comp .body .rc-anchor-normal{
        width: 373px !important;

    } */
}

@media (max-width: 375px) {
    .cust-verify-btn {
        margin-left: 215px !important;
        width: 63px !important;
        height: 25px !important;
        margin-top: 5px !important;


    }

    .btn-group-sm>.btn,
    .btn-sm {
        font-size: 0.7rem !important;
    }

    body {
        font-size: 0.6rem !important;
    }

    .text-verified {
        width: 54px !important;
        font-size: 0.6rem !important;
        margin-left: 230px !important;
        margin-top: -19px !important;

    }
}

@media (max-width: 360px) and (min-width: 320px) {
    .cust-verify-btn {
        margin-left: 177px !important;
        width: 55px !important;
        height: 20px !important;

    }

    .close-btn {
        margin-left: 227px !important;
        font-size: 0.7rem !important;
        line-height: 1 !important;
        margin-top: -3px !important;

    }

    .policies-connectionless-link :first-child {
        font-size: 10px !important;
    }

    .btn-group-sm>.btn,
    .btn-sm {
        font-size: 0.45rem !important;
    }

    body {
        font-size: 0.45rem !important;
    }

    .spinner-border-sm {
        width: 0.5rem !important;
        height: 0.5rem !important;
    }

    .text-verified {
        width: 54px !important;
        font-size: 10px !important;
        margin-left: 174px !important;
        margin-top: -19px !important;

    }

    .modal-content {
        width: 90% !important;
        /* margin-top: 240px !important; */
        margin-left: 17px !important;
    }

    .rc-anchor-normal {
        width: 373px !important;
    }

    .rc-anchor-normal .rc-anchor-pt {
        margin: 2px 86px 0 0 !important;

    }

    .grecaptcha-badge {
        display: none !important;
    }


}


@media (max-width: 425px) {
    .text-verified {
        width: 65px !important;
        font-size: 13px !important;
        margin-left: 210px !important;
        margin-top: -19px !important;


    }

    .list-group-item {
        margin-left: 0 !important;
    }
}

@media (max-width: 375px) {
    .text-verified {
        width: 55px !important;
        font-size: 0.6rem !important;
        margin-left: 175px !important;
        margin-top: -19px !important;
    }
}

@media (max-width: 320px) {
    .text-verified {
        width: 55px !important;
        font-size: 10px !important;
        margin-left: 173px !important;
        margin-top: -19px !important;
    }
}

@media (max-width: 1180px) and (min-width: 820px) {

    .text-verified {
        width: 100px !important;
        font-size: 13px !important;
        margin-left: 470px !important;
        margin-top: -19px !important;


    }

}

@media (max-width: 1024px) and (min-width: 893px) {

    .text-verified {
        width: 100px !important;
        font-size: 13px !important;
        margin-left: 530px !important;
        margin-top: -19px !important;


    }

}


@media (max-width: 668px) {


    .previous-button,
    .form-stepper-wrapper {
        display: none !important;
    }

    .back-button {
        display: block;
    }

    .text-verified {
        width: 63px !important;
        font-size: 13px !important;
        margin-left: 210px !important;
        margin-top: -19px !important;



    }

    .div-center-align {
        min-height: 0vh !important;
    }

    .policies-title h2 {
        font-family: Roboto !important;
        font-size: 20px
    }

    .policy-title {
        font-size: 12px
    }

    .policy-verify-button {
        font-size: 14px;
    }

    .summary-title>h2 {
        line-height: 1.15 !important;
    }

}

@media (max-width: 896px) and (min-width: 414px) {

    .text-verified {
        width: 65px !important;
        font-size: 13px !important;
        margin-left: 210px !important;
        margin-top: -19px !important;


    }




}

@media (max-width: 900px) and (min-width: 320px) {
    .categories-back-button {
        position: absolute;
        top: -1px;
        font-weight: bold;
        cursor: pointer;
    }
}

@media (max-width: 670px) and (min-width: 320px) {

    .text-verified {
        width: 60px !important;
        font-size: 13px !important;
        margin-left: 198px !important;
        margin-top: -19px !important;


    }

    .custom-btn-margin {
        top: 587px;
    }

    .ctrl-btn-prev {
        height: 42px !important;
        margin-left: 0px !important;
        font-size: 1rem !important;
    }

    .ctrl-btn-next {
        height: 42px !important;
        font-size: 1rem !important;
    }

    .h2,
    h2 {
        font-size: 1.5rem !important;
    }

    .category-item {
        width: 100%;
    }

    .prod-title>h2 {
        margin-top: 50px;
    }

}