$theme-colors: (
    "brand": #e5873b,
    "acme": #cd5241,
    "primary": #2aa7a7,

);

:root {
    --next-btn-border: #1274AC;
    --next-btn-bg: #1274AC;
    --next-btn-text: #fff;
    --selected-product-bg: #0f3a56;
    --stepper-bg: #1274AC;
    --stepper-border: #1274AC;
    --stepper-text: #fff;
}

$next-btn-border: var(--next-btn-border);
$next-btn-bg: var(--next-btn-bg);
$next-btn-text: var(--next-btn-text);
$selected-product-bg: var(--selected-product-bg);
$stepper-bg: var(--stepper-bg);
$stepper-border: var(--stepper-border);
$stepper-text: var(--stepper-text);