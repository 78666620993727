.carousel .thumb,
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: none;
}

.carousel-root {
  width: 100%;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 450px !important;
}

.ctrl-btn-prev {
  height: 50px !important;
  font-size: 18px !important;
}

.ctrl-btn-next {

  height: 50px !important;
  font-size: 18px !important;

}

.try-btn {

  height: 30px !important;
  font-size: 15px !important;

}

.spinner-border-sm {
  margin-left: 7px !important;
}

.qrcode-description h2 {
  color: red !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  white-space: pre-line;

}

.prod-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.categories-back-button {
  position: absolute;
  left: 0;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
}

.prod-title h2 {
  font-weight: 350 !important;
}

.privacy-statement h2 {
  font-weight: 350 !important;

}

.phn-title h2 {
  font-weight: 350 !important;
}

.policies-title h2 {
  font-family: Roboto !important;
  font-weight: 350 !important;

}

.questions-container {
  font-family: Roboto !important;
}

.summary-title h2 {
  font-weight: 350 !important;
}

.carousel .control-dots .dot {
  width: 15px;
  height: 16px;
}

.priv-state p {
  justify-content: center;
  font-size: 1rem;
  text-align: justify;
  font-weight: 400 !important;
  padding: 5px 20px 5px 5px;

}

.list-check {
  margin-left: 0px !important;

}

.list-check2 {
  margin-left: 20px !important;

}

.card-img-top {
  width: 250px !important;
  height: 85px !important;
}

.deep-link-btn {
  height: 40px;
  font-size: 20px;
}

.privacy-bg-lightGray {
  background-color: #e9ecef;
}

.policy-bottom {
  position: absolute;
  margin-top: 0rem;
}

.carousel .slider-wrapper {
  border-radius: 8px !important;
}

.accordion-body .scrollspy-example {
  bottom: 15px !important;
  max-height: 170px !important;
  overflow: auto !important;

}

.accordian-data {
  // margin-left: -20px !important;

}

.accordion-body {
  padding: 1rem 1.25rem;
  background: #e9ecef;
}

.scrollspy-example::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e9ecef;
  border-radius: 10px;
  background-color: white;
}

.scrollspy-example::-webkit-scrollbar {
  width: 3px;
  background-color: white;
}

.scrollspy-example::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(13, 12, 12, 0.3);
  background-color: white;
}

.policies-connectionless-link {
  color: rgb(5, 165, 251);
  text-decoration: underline;
  padding: 10px 00px 0px 00px;
}

.close-btn {
  // padding: 0.25rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  width: 25px;
  border-radius: 0.3rem;
  color: rgb(244, 13, 13);
  background-color: rgb(254, 252, 252);
  border-color: #e70000;
  position: absolute;
  top: 8px;
  left: 6px;
  z-index: 1;
}

.next-btn-ind-4 {
  width: 100% !important;
  height: 3rem;
  top: 500px;
}

.modal-content {
  width: 112% !important;
}

// NEW SCSS

.form-wrapper {
  margin: 0.75rem 1rem 1rem;
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 2px 2px 10px #fff;
  width: clamp(320px, 100%, 70%);
  border: 1px solid;
}

.back-button {
  margin: 0rem 1rem;
  padding: 0rem 2rem;
  width: clamp(320px, 100%, 70%);
  color: white;
  display: none;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.form-stepper-wrapper {
  width: clamp(320px, 100%, 70%);
  margin: auto;
}

.btn-blue {
  border-color: $next-btn-border !important;
  color: $next-btn-text !important;
  background-color: $next-btn-bg !important;
}

.div-center-align {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-edges-box {
  --border-size: 32px;
  --border-color: black;
  background:
    linear-gradient(var(--border-color) var(--border-size), transparent 0 calc(100% - var(--border-size)), var(--border-color) 0) 0 0 / 4px 100%,
    linear-gradient(var(--border-color) var(--border-size), transparent 0 calc(100% - var(--border-size)), var(--border-color) 0) 100% 0 / 4px 100%,
    linear-gradient(to right, var(--border-color) var(--border-size), transparent 0 calc(100% - var(--border-size)), var(--border-color) 0) 0 0 / 100% 4px,
    linear-gradient(to right, var(--border-color) var(--border-size), transparent 0 calc(100% - var(--border-size)), var(--border-color) 0) 0 100% / 100% 4px;
  background-repeat: no-repeat;
  padding: 20px;
  display: inline-block;
}

// Checkbox and Radio CSS
.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 10px;
  position: relative;
  width: 100%;

  label:hover {
    background-color: lightgray;
  }

  label {
    padding: 12px 50px;
    border-radius: 5px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3C454C;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    border: 1px solid lightgray;
    font-family: Roboto !important;

    &:before {
      width: 50px;
      height: 10px;
      border-radius: 50%;
      content: '';
      background-color: $selected-product-bg;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: '';
      border: 2px solid #D1D7DC;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      z-index: 2;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }

    .label-subcontainer {
      .m-1-price {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 15px !important;
      }

      .m-1-category {
        font-size: 14px;
        font-weight: bold;
      }

      .m-1-more-details {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  label.checkbox {
    &:after {
      border-radius: 20%;
    }
  }

  label.radio {
    &:after {
      border-radius: 50%;
    }
  }

  input:checked~label {
    color: #fff;

    h2 {
      color: #fff;
    }

    a {
      color: lightgray;
    }

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #54E0C7;
      border-color: #54E0C7;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

// Form Stepper CSS 
.k-step-text {
  color: white;

  :hover {
    color: white;
  }
}

.k-step.k-step-success .k-step-indicator {
  border-color: #cccccc;
  color: #ffffff;
  background-color: #1274ac;
  border: 2px solid;
}

.k-step.k-step-success .k-step-indicator::after {
  display: none;
}

.cust-prod-page .nav .active {
  background-color: #12232E;
  color: #fff;
  border-radius: 5px;
}

// Payment Step
.payment-method-box .payment-method-card {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #fff;
  border-radius: 0px;
  cursor: pointer;
  align-items: center;
}

.payment-method-box .payment-method-card .img-box {
  width: 100px;
  // height: 50px;
}

.payment-method-box .payment-method-card img {
  width: 100%;
  object-fit: fill;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000ad;
  z-index: 10;

  .spinner-border {
    border-right-color: #12232E !important;
    border-left-color: #fff !important;
    border-top-color: #fff !important;
    border-bottom-color: #fff !important;
  }
}

.circlespinner {
  margin: calc(50vh - 1em) calc(50vw - 1em);
}

.k-stepper .k-step-current .k-step-indicator {
  border-color: $stepper-border !important;
  color: $stepper-text !important;
  background-color: $stepper-bg !important;
}

.cursor-pointer {
  cursor: pointer;
}

.payment-modal-close-btn {
  border: 1px solid;
  border-radius: 100%;
  padding: 1px 7px 2px 7px;
}

.questions-container {
  font-family: Roboto !important;
}