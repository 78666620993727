@media (max-width: 668px) {
  .inputGroup {
    margin: 0 !important;
    label {
      border-radius: 30px;
      padding: 17px 5px 17px 50px;
      font-family: Roboto !important;

      h2 {
        font-size: 18px;
        font-weight: normal;
        padding: 0;
        margin: 0;
      }

      .label-subcontainer {
        p, a {
          padding: 0 !important;
          margin: 0 !important;
          line-height: 1.5;
        }
      
        .m-1-price {
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 15px !important;
        }
        
        .m-1-category {
          font-size: 14px;
          font-weight: bold;
        }
        
        .m-1-more-details {
          font-size: 12px;
          font-weight: normal;
        }
      }      
    }
  }

  .prod-title h2 {
    font-size: 20px !important;
  }
  
  .questions-container {
    font-family: Roboto !important;
  }

  .question-text {
    font-size: 14px;
  }

  .summary-title h2 {
    line-height: 1;
  }
}